import { Flex, Link, Text } from "gestalt";
import { Suspense, useState } from "react";
import CookiePrefrences from "./CookiePrefrences";

export default function Footer() {
  const [cookie, setCookie] = useState(false);

  return (
    <Flex gap={3} direction="column">
      <Flex justifyContent="between">
        <Flex gap={2} justifyContent="start" wrap>
          <Link
            underline="hover"
            display="inline"
            href="https://retentionscript.com/privacy-policy"
          >
            <Text size="100" color="subtle" inline overflow="noWrap">
              PRIVACY
            </Text>
          </Link>

          <Link
            underline="hover"
            display="inline"
            href="https://retentionscript.com/terms-of-service"
          >
            <Text size="100" color="subtle" inline overflow="noWrap">
              TERMS
            </Text>
          </Link>
          <Link
            underline="hover"
            display="inline"
            href="https://retentionscript.com/refund-policy"
          >
            <Text size="100" color="subtle" inline overflow="noWrap">
              OTHER POLICIES
            </Text>
          </Link>
        </Flex>
        <Flex gap={2} justifyContent="start" wrap>
          <Link
            underline="hover"
            display="inline"
            href="https://retentionscript.com/blog"
          >
            <Text size="100" color="subtle" inline overflow="noWrap">
              BLOG
            </Text>
          </Link>
          <Link
            underline="hover"
            display="inline"
            href="https://community.retentionscript.com"
          >
            <Text size="100" color="subtle" inline overflow="noWrap">
              COMMUNITY
            </Text>
          </Link>
        </Flex>
      </Flex>
      <Text size="100" color="subtle">
        ©{" "}
        {new Date().toLocaleDateString("en-US", {
          year: "numeric",
        })}{" "}
        Cartegan Software, Pvt Ltd.
      </Text>
      {/* <Text size="100" color="subtle">
        Helping your product fall in ❤️ with your customers.
      </Text> */}

      {cookie && (
        <Suspense>
          <CookiePrefrences dismiss={() => setCookie(false)} />
        </Suspense>
      )}
    </Flex>
  );
}
